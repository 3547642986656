/* @import 'https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap'; */
/* @import 'https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap'; */

html,
body {
    margin: 0;
    padding: 0
}

body {
    /* font-family: rubik, sans-serif; */
    font-family: "Kanit", sans-serif;
    /* font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0;
    color: rgba(3, 3, 15, .8); */
    overflow-x: hidden;
}

p {
    margin: 0 0 0px
}

h1,
h2,
h3,
h4,
h5,
h6 {
    /* font-family: work sans, sans-serif; */
    font-family: "Kanit", sans-serif;
    font-weight: 700;
    color: #03030f;
    line-height: 1.5;
    margin: 0 0 10px
}

/* .headFont {
    font-family: work sans, sans-serif
} */

:root {
    --theme-color: #ff790d
}

a {
    color: var(--theme-color);
    text-decoration: none;
    transition: all ease 400ms;
    -moz-transition: all ease 400ms;
    -webkit-transition: all ease 400ms
}

a:hover {
    color: var(--theme-color);
    text-decoration: none
}

a:hover {
    color: #03030f;
    text-decoration: none
}

img {
    max-width: 100%;
    height: auto;
}

/* Top header */
.header01 .header-top {
    border-bottom: 1px solid #0003;
    padding: 6px 0px;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

ul,
li {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.header01 .header-top .info-box li {
    position: relative;
    display: inline-block;
    padding: 0px 20px 0px 20px;
    /* margin-right: 15px; */
}

.header01 .header-top .info-box li:before {
    position: absolute;
    content: '';
    background: #d5d5d5;
    width: 1px;
    height: 20px;
    top: 5px;
    right: 0px;
}

.header01 .header-top .info-box li:last-child:before {
    display: none;
}

.header01 .header-top .info-box li:last-child {
    margin-right: 0px;
}

.header01 .header-top .info-box li i {
    /* position: absolute;
    left: 0px;
    top: 0px;
    font-size: 18px; */

    font-size: 18px;
    line-height: 20px;
    color: #F58634;
    margin-right: 15px;
}

.header01 .header-top .info-box li a {
    position: relative;
    display: inline-block;
    font-size: 16px;
    line-height: 17px;
    color: #0c0d24;
    /* font-weight: 500; */
}

.header01 .header-top .info-box li a:hover {
    color: #F58634;
}

.header-top .request-btn {
    position: relative;
    float: left;
    margin-left: 35px;
}

.main-header.style-four .header-top .request-btn i {
    font-size: 14px;
    font-weight: 400;
}

/* Top header end */
/* header start */
.header01 {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    background: #fff0e6c7;
    padding: 0px 95px
}

.header01 .btn-item {
    transform: none !important;
}

.navBar01 {
    justify-content: space-between;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center
}

.logo {
    line-height: 40px;
    margin: 0;
    padding: 0
}

.logo img {
    /* height: 40px; */
    max-width: 100%
}

.navArea {
    display: flex;
    flex-basis: auto;
    align-items: center
}

.menuToggler {
    display: none
}

.menu_btn {
    display: none
}

.mainMenu {
    position: relative;
    margin-right: auto;
    padding-left: 115px
}

.mainMenu ul {
    margin: 0;
    padding: 0
}

.mainMenu ul li {
    line-height: .8;
    margin: 0 44px 0 0;
    padding: 30px 0;
    display: inline-block;
    position: relative
}

.mainMenu ul li:last-child {
    margin-right: 0
}

.mainMenu ul li a {
    font-size: 20px;
    color: #0c0d24;
    /* display: block; */
    display: flex;
    letter-spacing: .5px;
    line-height: .7;
    text-transform: capitalize
}

.mainMenu ul li.menu-item-has-children>a:after {
    content: '+';
    display: inline-block;
    letter-spacing: 0;
    font-size: 20px;
    line-height: 10px;
    color: inherit;
    position: relative;
    font-weight: 500;
    top: 0px;
    margin-left: 5px
}

.mainMenu ul li>a:hover,
.mainMenu ul li.current-menu-item>a {
    color: var(--theme-color)
}

.mainMenu ul li.active>a {
    color: var(--theme-color);
    letter-spacing: 1px;
}

.mainMenu>ul ul {
    position: absolute;
    top: 100%;
    left: -20px;
    min-width: 290px;
    /* min-width: 220px; */
    border-radius: 2px;
    background: #fff;
    visibility: hidden;
    opacity: 0;
    box-shadow: 0 9px 52px -14px rgba(0, 0, 0, .17);
    z-index: 10;
    -webkit-transform: rotateX(-75deg);
    -moz-transform: rotateX(-75deg);
    transform: rotateX(-75deg);
    transform-origin: 0% 0%;
    -webkit-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    -webkit-transition: .4s;
    transition: .4s
}

.mainMenu>ul ul li {
    padding: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
    display: block;
    width: 100%
}

.mainMenu>ul ul li:first-child {
    border-top: none
}

.mainMenu>ul ul li.menu-item-has-children>a:after {
    float: right
}

.mainMenu>ul ul li a {
    font-size: 17px;
    line-height: 24px;
    padding: 11px 20px
}

.mainMenu>ul ul li a:hover {
    letter-spacing: 1px;
}

.mainMenu>ul ul ul {
    left: calc(100% - -1px);
    top: 0;
    margin: 0
}

.mainMenu>ul li:hover>ul {
    pointer-events: inherit;
    visibility: visible;
    opacity: 1;
    -webkit-transform: rotatex(0deg);
    -moz-transform: rotatex(0deg);
    transform: rotatex(0deg)
}

header.fixedHeader {
    padding-top: 0;
    padding-bottom: 0;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: #fff0e6c7;
    /* background: transparent; */
    backdrop-filter: blur(10px);
    /* background: #fff; */
    z-index: 99999;
    box-shadow: 0 0 25px rgba(0, 0, 0, .15)
}

header.fixedHeader .header-top {
    display: none;
}

.accessNav {
    display: flex;
    justify-content: flex-end;
    align-items: center
}

.accessNav .phoneBtn {
    color: #03030f;
    font-size: 20px;
    line-height: 26px
}

.accessNav .phoneBtn i {
    font-size: 27px;
    margin-right: 10px;
    position: relative;
    top: 7px
}

.accessNav .phoneBtn:hover {
    color: var(--theme-color)
}

.accessNav .cartBtn {
    position: relative;
    font-size: 27px;
    color: #03030f;
    margin-left: 25px
}

.accessNav .cartBtn span {
    background: var(--theme-color);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    right: -12px;
    top: 0;
    font-size: 15px;
    color: #fff;
    line-height: 20px;
    text-align: center;
    display: inline-block
}

.accessNav .cartBtn:hover {
    color: var(--theme-color)
}

.accessNav .dgBtn_two {
    margin-left: 38px;
    height: 55px;
    line-height: 55px;
    font-size: 16px;
    color: #fff;
    border: none;
    text-transform: capitalize;
    letter-spacing: .4px;
    font-weight: 400;
    background: #65d0ff;
    padding: 0 33px
}

.accessNav .dgBtn_two i {
    font-size: 22px;
    margin: 0 8px 0 0;
    top: 4px
}

.accessNav .dgBtn_two::after {
    background: var(--theme-color);
    visibility: hidden;
    opacity: 0
}

.accessNav .dgBtn_two:hover::after {
    visibility: visible;
    opacity: 1
}

.centerMenu .mainMenu {
    margin-right: 0;
    margin-left: auto;
    padding-left: 0;
    padding-right: 0px;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px
}

.content_coming_soon input[type=email]::-moz-placeholder {
    color: rgba(3, 3, 15, .7);
    opacity: 1
}

.content_coming_soon input[type=email]::-ms-input-placeholder {
    color: rgba(3, 3, 15, .7);
    opacity: 1
}

.content_coming_soon input[type=email]::-webkit-input-placeholder {
    color: rgba(3, 3, 15, .7);
    opacity: 1
}

.subscribeForm .notes {
    margin: 0;
    color: #0c0d24;
    font-size: 16px
}

.pnl {
    animation-delay: 2s;
    animation-name: fadeInUp
}

.bLayer {
    position: relative;
    margin: 100px 0 0
}

.bLayer img {
    max-width: none;
    width: auto
}

.layer_img {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    display: inline-block;
    top: 57%;
    transform: translateY(-50%)
}

/* header end */

/* banner */

.pageBanner {
    position: relative;
    height: 350px;
    background-color: #fff0e6;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-image: url('../images/bg/breadcumb.png');
}

.vmiddle {
    position: relative;
    top: 57%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%)
}

.banner-title {
    position: relative;
    font-size: 52px;
    line-height: 60px;
    margin-bottom: 0px
}

.banner-desc {
    font-size: 22px;
    line-height: 36px;
    color: #0c0d24;
    margin: 0 40px
}


/* end banner */
/* slider */
.slider_02 {
    position: relative;
    margin: 0;
    overflow: hidden;
}

.slider_02 .tparrows.custom {
    width: 65px;
    height: 65px;
    background: #fbbe95;
    color: var(--theme-color);
    border-radius: 50%;
    transition: all ease 300ms;
    -moz-transition: all ease 300ms;
    -webkit-transition: all ease 300ms;
    -o-transition: all ease 300ms;
    -ms-transition: all ease 300ms
}

.slider_02 .tparrows.custom:before {
    line-height: 65px;
    font-size: 22px;
    color: #fff
}

.slider_02 .tparrows.custom:hover {
    background: var(--theme-color)
}

.slider_02 .tparrows.custom:hover:before {
    color: #fff
}

.layer video {
    position: relative;
    display: block !important;
    bottom: 280px;
}

.tp-bullets .tp-bullet {
    background: 0 0;
    text-align: center;
    width: 16px;
    height: 16px;
    border: 2px solid #fbbe95;
    border-radius: 50%;
    transition: all ease 400ms !important;
    -moz-transition: all ease 400ms !important;
    -webkit-transition: all ease 400ms !important
}

.tp-bullets .tp-bullet-inner {
    width: 0;
    height: 0;
    display: block;
    background: 0 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
    text-align: center;
    transition: all ease 400ms !important;
    -moz-transition: all ease 400ms !important;
    -webkit-transition: all ease 400ms !important
}

.tp-bullets .tp-bullet:hover .tp-bullet-inner,
.tp-bullets .tp-bullet.selected .tp-bullet-inner {
    background: var(--theme-color);
    width: 6px;
    height: 6px
}

.tp-bullets .tp-bullet:hover,
.tp-bullets .tp-bullet.selected {
    border-color: var(--theme-color)
}

.tp-bannertimer {
    display: none !important
}

.ws_nowrap {
    white-space: normal !important
}

.tp-caption .dgBtn {
    font-size: 17px !important;
    line-height: 67px !important;
    transition: all ease 400ms !important;
    -moz-transition: all ease 400ms !important;
    -webkit-transition: all ease 400ms !important
}

.tp-caption .subbg {
    font-size: 20px;
    line-height: 26px;
    color: #03030f;
    display: inline-block;
    background: #e3f5fd;
    letter-spacing: .62px
}

/**/
.slider_03 {
    position: relative;
    margin: 0;
    overflow: hidden
}

.slider_03 .dgBtn {
    border-radius: 50px
}

.slider_03 .tparrows.custom {
    width: 65px;
    height: 65px;
    background: #fbbe95;
    transition: all ease 300ms;
    -moz-transition: all ease 300ms;
    -webkit-transition: all ease 300ms;
    -o-transition: all ease 300ms;
    -ms-transition: all ease 300ms
}

.slider_03 .tparrows.custom:before {
    line-height: 65px;
    font-size: 22px;
    color: #03030f
}

.slider_03 .tparrows.custom:hover {
    background: var(--theme-color)
}

.slider_03 .tparrows.custom:hover:before {
    color: #03030f
}

.rvlist {
    display: inline-block;
    list-style: none;
    width: 100%;
    font-size: 22px;
    color: #0c0d24;
    line-height: 32px;
    margin: 0 0 18px
}

.rvlist i {
    width: 34px;
    height: 34px;
    background: #fff;
    border-radius: 50%;
    text-align: center;
    font-size: 18px;
    line-height: 34px;
    display: inline-block;
    margin-right: 10px;
    transition: all ease 300ms;
    -moz-transition: all ease 300ms;
    -webkit-transition: all ease 300ms
}

.rvlist:hover i {
    background: var(--theme-color)
}

/* slider end */

/* Preloader */

.preloader {
    position: fixed;
    background-color: #ffffff;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: 9999;
}

.clear-loader {
    background: #ffffff;
    bottom: 0;
    height: 100%;
    left: 0;
    overflow: hidden !important;
    position: fixed;
    right: 0;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 8;
}

.preloader .logo_loader {
    display: block;
    width: 80px;
    height: 80px;
    background: url(../images/loader-icon.png) no-repeat;
    z-index: 9;
    margin: 30px 0px 0px 0px;
    overflow: hidden;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: flipInY;
    animation-name: flipInY;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.preloader .logo_loader span {
    position: absolute;
    display: block;
    height: 100%;
    left: 0px;
    right: 0px;
    bottom: 0%;
    /* background-color: #FFFFFF; */
    opacity: 0.7;
    filter: alpha(opacity=70);
    -webkit-animation: logo 2s infinite;
    -moz-animation: logo 2s infinite;
    -ms-animation: logo 2s infinite;
    -o-animation: logo 2s infinite;
    animation: logo 2s infinite;
}

.loader p {
    font-size: 42px;
    font-weight: 400;
    line-height: 1;
    font-family: 'Outfit', sans-serif;
    color: #fff;
    text-shadow: 0 0 2px #000, 0 0 1px #000, 0 0 1px #000;
    letter-spacing: 2px;
    position: relative;
    margin-left: 20px;
    text-transform: uppercase;
}

.loader p::after {
    content: "Shree";
    position: absolute;
    left: 0;
    top: 3px;
    color: #000000;
    width: 100%;
    height: 100%;
    overflow: hidden;
    -webkit-animation: animloader 6s linear infinite;
    animation: animloader 6s linear infinite;
}

.loader span {
    font-size: 30px;
    font-weight: 400;
    line-height: 1;
    font-family: 'Outfit', sans-serif;
    color: #fff;
    text-shadow: 0 0 2px #F58634, 0 0 1px #F58634, 0 0 1px #F58634;
    letter-spacing: 2px;
    position: absolute;
    display: block;
}

.loader span::after {
    content: "Softech";
    position: absolute;
    left: 0;
    top: 3px;
    color: #F58634;
    width: 100%;
    height: 100%;
    overflow: hidden;
    -webkit-animation: animloader 6s linear infinite;
    animation: animloader 6s linear infinite;
}

@-webkit-keyframes logo {
    0% {
        bottom: 0%;
    }

    20% {
        bottom: 25%;
    }

    60% {
        bottom: 50%;
    }

    100% {
        bottom: 100%;
    }
}

@-moz-keyframes logo {
    0% {
        bottom: 0%;
    }

    20% {
        bottom: 25%;
    }

    60% {
        bottom: 50%;
    }

    100% {
        bottom: 100%;
    }
}

@-ms-keyframes logo {
    0% {
        bottom: 0%;
    }

    20% {
        bottom: 25%;
    }

    60% {
        bottom: 50%;
    }

    100% {
        bottom: 100%;
    }
}

@-o-keyframes logo {
    0% {
        bottom: 0%;
    }

    20% {
        bottom: 25%;
    }

    60% {
        bottom: 50%;
    }

    100% {
        bottom: 100%;
    }
}

@keyframes animloader {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}

/*End Pre loder*/

/* node cursor */

.cursor {
    pointer-events: none;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    display: block;
    transition: opacity .3s linear;
    opacity: 0;
    width: 10px;
    height: 10px;
    z-index: 9999999999;
}

.cursor.moving {
    opacity: 1;
}

.cursor::before {
    content: ' ';
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: #F58634;
    border-radius: 50%;
    top: 0;
    left: 0;
    opacity: 1;
    transition: opacity .3s linear;
}

.cursor.expand::before {
    opacity: 0;
}

.node {
    pointer-events: none;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    display: block;
    transition: opacity .3s linear;
    opacity: 0;
    width: 30px;
    height: 30px;
    z-index: 9999999999;
}

.node.moving {
    opacity: 1;
}

.node::before {
    content: ' ';
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: transparent;
    border-radius: 50%;
    top: 0;
    left: 0;
    border: 1px solid #000000;
    transform: scale(1.5);
    opacity: 1;
    transition: opacity 300ms linear, transform 300ms linear, border 300ms linear, background-color 300ms linear;
}

.node.expand::before {
    transform: scale(2);
    background-color: #000000;
    opacity: 0.3;
}


/* end node cursor */
/* partner client */

.clients-section {
    position: relative;
    padding: 70px 0px;
}

.clients-section .owl-carousel .owl-stage-outer {
    padding: 20px 0px;
}

.clients-section .owl-theme .image-box {
    margin: 0px 15px;
}

.clients-section .owl-carousel {
    margin: 0px -15px;
    width: auto;
}

.clients-section .image-box {
    position: relative;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
}

.clients-section .image-box img {
    position: relative;
    display: inline-block;
    width: auto;
    max-width: 100%;
}



.clients-section .owl-dots,
.clients-section .owl-nav {
    display: none;
}


.clients-section .image-box .hover-logo {
    transition: all 0.3s ease-in-out;
    /* display: block; */
    position: absolute;
    z-index: 1;
    visibility: hidden;
    filter: grayscale(0%);
    webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
}

.clients-section .image-box .main-logo {
    filter: grayscale(100%);
    transition: all 0.3s ease-in-out;
    /* display: block; */
}

.clients-section .image-box:hover .hover-logo {
    position: absolute;
    z-index: 1;
    /* display: block; */
    opacity: 3;
    visibility: visible;
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -o-transform: translateY(0%);
    transform: translateY(0%);
    filter: grayscale(0%);
    cursor: pointer;
    /* webkit-filter: brightness(0) invert(1);
    filter: brightness(1) invert(1); */
}

.clients-section .image-box:hover .main-logo {
    /* display: block; */
    visibility: hidden;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
    transform: translateY(100%);
    filter: grayscale(100%);
}


/* end partner client  */


/* working process */

.working-section {
    overflow: hidden;
    position: relative;
    /* padding: 70px 0; */
}

#svg-container {
    position: absolute;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%);
    /* width: 65%; */
    top: 50%;
    width: 1400px;
    margin-left: 50px;
}

#svgC {
    margin: 0 auto;
    width: 100%;
}

.work-process {
    position: relative;
}

.step-num-box {
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
    border-radius: 50%;
    padding: 6px;
    box-sizing: border-box;
    text-decoration: none;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.15);
    /* border: 3px solid #fafaff;
    box-shadow: 0 5px 8px rgb(0 0 0 / .15); */
    background: #ffffff;
    transition: .5s;
}
.step-num-box:before {
    animation: focuse 1.5s linear 0s infinite normal none;
    border: 1px solid rgba(0, 0, 0, .35);
    border-radius: 50%;
    content: "";
    height: calc(100% + 16px);
    left: -8px;
    position: absolute;
    top: -8px;
    width: calc(100% + 16px);
}
/* .step-num-box:after {
    animation: slide 1s linear infinite;
    background: url(../images/icons/dash.png) repeat 0 0;
    content: "";
    height: 2px;
    left: 100%;
    position: absolute;
    top: 50%;
    width: 100%;
    z-index: -1;
} */
@keyframes focuse {
    0% {
        transform: scale(0.9);
        opacity: 1;
    }

    75% {
        transform: scale(1.2);
        opacity: 0;
    }

    100% {
        transform: scale(1.2);
        opacity: 0;
    }
}

/* @keyframes slide {
    from {
        background-position: 0 0;
    }

    to {
        background-position: 40px 0;
    }
} */
/* .step-num-box2:after {
    display: none!important;
} */
.step-icon {
    width: 120px;
    height: 120px;
    position: relative;
    line-height: 120px;
    font-size: 40px;
    text-align: center;
    color: #1c1d3e;
    /* background: linear-gradient(0deg, #ffffff, #ededed); */
    border-radius: 50%;
}

.step-icon span {
    position: relative;
}

.working-section a:hover img{
    animation: jello-horizontal .9s both;
}
@-webkit-keyframes jello-horizontal {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
    }

    40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
    }

    50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
    }

    65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
    }

    75% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
    }

    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes jello-horizontal {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
    }

    40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
    }

    50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
    }

    65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
    }

    75% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
    }

    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.step-icon img {
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.18));
}

.step-num {
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 5px 15px 0 rgba(245, 134, 52, 0.4);
    color: #F58634;
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    height: 50px;
    right: 0;
    line-height: 55px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 50px;
}

.work-process h4 {
    color: #F58634;
    font-weight: 400;
}

/* end working process */

/* portfolio */
.language-box {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
    transition: all 500ms ease;

}

.language-box span {
    position: absolute;
    top: 16px;
    right: 70px;
    width: 128px;
    display: inline-block;
    background: #fff;
    font-size: 18px;
    line-height: 26px;
    /* font-family: 'Josefin Sans', Sans-serif; */
    padding: 7px;
    text-align: center;
    border-radius: 3px;
    opacity: 0;
    visibility: hidden;
    transition: all 500ms ease;
}

.language-box span:before {
    position: absolute;
    content: '';
    background: #fff;
    width: 12px;
    height: 12px;
    top: 14px;
    right: -5px;
    transform: rotate(45deg);
}

.portfolio__item:hover span {
    right: 90px;
    opacity: 1;
    visibility: visible;
}

.language-box img {
    width: auto;
    border-radius: 50%;
}

.language-box .client-logo {
    position: relative;
    display: inline-block;
    background: #fff;
    width: 72px;
    height: 72px;
    text-align: center;
    border-radius: 50%;
    padding: 10px;
    /* cursor: pointer; */
}

/* portfolio end */
/* top button */
#backtotop {
    position: fixed;
    right: 30px;
    bottom: -30px;
    width: 45px;
    height: 45px;
    background: #03030f;
    color: #fff;
    font-size: 22px;
    text-align: center;
    line-height: 45px;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 999;
    border-radius: 2px
}

#backtotop i {
    line-height: inherit
}

#backtotop:hover {
    background: #F58634
}

::-webkit-scrollbar {
    background-color: #ccc;
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: #F58634;
    border-radius: 20px;
}

::-moz-selection {
    background: #FF7906;
    color: #fff;
    text-shadow: none;
}

::selection {
    background: #2575fc;
    color: #fff;
    text-shadow: none;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/*chrome*/
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

/* top button */

/* footer */

.tw-footer {
    /* padding: 75px 0 0; */
    background-color: #151414;
    position: relative;
    z-index: 2;
}

.tw-footer .row {
    justify-content: center;
}

.tw-footer .contact-us {
    margin-top: 0;
    margin-bottom: 25px;
    padding-left: 0px;
}

.tw-footer .contact-us .contact-info {
    margin-left: 60px;
}

.tw-footer .contact-us.contact-us-last {
    margin-left: 0px;
    margin-bottom: 0px;
}

.tw-footer .contact-icon i {
    font-size: 24px;
    top: 0px;
    position: relative;
    color: #FA6742;
}

.tw-footer .contact-us .contact-info p a {
    color: #fff;
}

.tw-footer .contact-us .contact-info p a:hover {
    color: #F58634;
}

.tw-footer-info-box {
    position: relative;
    /* top: -75px; */
    /* top: -122px; */
    background: #202020;
    padding: 40px 50px;
    z-index: 2;
}

.tw-footer-info-box:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 45px;
    background: #202020;
    clip-path: polygon(100% 0, 100% 100%, 0 100%);
    top: -43px;
    left: 0;
}

.tw-footer-info-box:after {
    position: absolute;
    content: "";
    width: 35px;
    height: 45px;
    background: #3c3b3b;
    clip-path: polygon(0 0, 95% 100%, 0 100%);
    top: -44px;
    right: -34px;
}

.contact-info {
    margin-left: 75px;
    color: #fff;
}

.contact-info h3 {
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0;
}

.contact-icon {
    position: absolute;
    vertical-align: middle;
    width: 40px;
}

.contact-icon i {
    font-size: 36px;
    top: -5px;
    position: relative;
    color: #FA6742;
}

.contact-info {
    margin-left: 75px;
    color: #fff;
}

.contact-info p{
    font-size: 18px;
}
/**/

.footer-left-widget {
    padding-left: 0px;
}

.footer-widget {
    padding-top: 50px;
}

.footer-widget .section-heading {
    margin-bottom: 0px;
}

.footer-widget h3 {
    font-size: 24px;
    font-weight: 600;
    color: #F58634;
    position: relative;
    /* margin-bottom: 25px; */
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.footer-widget ul {
    width: 100%;
    float: left;
    list-style: none;
    margin: 0;
    padding: 0;
}

.footer-widget li {
    margin-bottom: 18px;
    position: relative;
}

.footer-widget li a:before {
    position: absolute;
    content: "";
    top: 50%;
    left: -15px;
    width: 6px;
    height: 6px;
    /* border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%; */
    background-color: var(--theme-color);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    transition: all 0.2s linear;
    visibility: hidden;
    opacity: 0;
}

.footer-widget li a:hover:before {
    left: 0px;
    visibility: visible;
    opacity: 1;
}

.footer-widget a {
    color: #ffffff;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

footer ul {
    margin: 0;
    padding: 0
}

footer ul li {
    list-style: none;
    display: block;
    font-size: 18px;
    line-height: 26px;
    margin: 0 0 10px
}

footer ul li a {
    color: inherit;
    display: inline-block
}

footer ul li a:hover {
    color: var(--theme-color);
}

footer ul li a:hover {
    color: var(--theme-color);
    padding-left: 15px;
}


/**/

.footer-social-icon.footer-social-link ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.footer-social-icon.footer-social-link li {
    display: block;
}

.footer-social-icon.footer-social-link li a:before,
.footer-social-icon.footer-social-link li a:hover:before {
    content: none;
}

.footer-social-icon.footer-social-link i {
    /* display: block; */
    width: 35px;
    height: 35px;
    border-radius: 50%;
    text-align: center;
    line-height: 35px;
    background: #000;
    margin-right: 10px;
    font-size: 18px;
    color: #fff;
    letter-spacing: 0px;
    text-shadow: 3px 3px 2px rgba(51, 51, 51, 0.7);
}

.footer-social-icon.footer-social-link li:hover i {
    text-shadow: 6px 6px 10px rgba(51, 51, 51, 1);
}

.footer-social-icon.footer-social-link li a:hover {
    padding-left: 0px;
    /* -webkit-transform: translateY(-5px);
        -ms-transform: translateY(-5px);
        transform: translateY(-5px); */
}

.footer-social-icon.footer-social-link i.fa-facebook {
    background-color: #3B5998;
    /* border: 1px solid #3B5998;
        color: #3B5998; */
}

.footer-social-icon.footer-social-link i.fa-twitter {
    background-color: #55ACEE;
    /* border: 1px solid #55ACEE;
        color: #55ACEE; */
}

.footer-social-icon.footer-social-link i.fa-youtube-play {
    background-color: #bb0000;
    /* border: 1px solid #bb0000;
        color: #bb0000; */
}

.footer-social-icon.footer-social-link i.fa-linkedin {
    background-color: #007bb6;
    /* border: 1px solid #007bb6;
        color: #007bb6; */
}

.footer-social-icon.footer-social-link i.fa-instagram {
    background-color: #bc2a8d;
    /* border: 1px solid #bc2a8d;
        color: #bc2a8d; */
}

/* footer bottom */

.copyright {
    font-size: 18px;
    padding: 20px 0;

    background-color: #202020;
}

.copyright span,
.copyright a {
    color: #ffffff;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.copyright a:hover {
    color: #FA6742;
}

.copyright-menu ul {
    text-align: right;
    margin: 0;
}

.copyright-menu li {
    display: inline-block;
    padding-left: 20px;
}


/* end footer */
email::after {
    content: attr(data-domain)
}

email::before {
    content: attr(data-user) "\0040"
}

.grecaptcha-badge{
    z-index: 999999;
}
.jq-toast-wrap.top-right {
    top: 85px!important;
    right: 40px!important;
    font-size: 16px!important;
}
.close-jq-toast-single{
    font-size: 20px!important;
}
